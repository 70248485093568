import React from "react"
import { graphql } from "gatsby"
import BlogListPage from "../components/blog/blog-list-page"
import { useTranslation } from "gatsby-plugin-react-i18next"

function BlogPage({ data, pageContext }) {
  const { allStrapiBlog, recentPosts, archives } = data
  const { t } = useTranslation()

  const seo = {
    metaTitle: t('our blog'),
    lang: pageContext.strapiLangKey,
  }

  return (
    <BlogListPage {...{
      seo,
      data: allStrapiBlog.nodes,
      recentPosts,
      archives,
      ...data
    }} />
  )
}

export const query = graphql`
  query(
    $language: String!
    $strapiLangKey: String!
  ) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      ...Locales
    }
    strapiGlobal(locale: {eq: $strapiLangKey}) {
      ...ContactDetails
    }
    archives: allStrapiBlog (
      filter: {
        locale: {
          eq: $strapiLangKey
        }
      }
      sort: {
        fields: publishDate,
        order: DESC
      }
    ) {
      nodes {
        publishDate
      }
    }
    recentPosts: allStrapiBlog (
      filter: {
        locale: {
          eq: $strapiLangKey
        }
      }
      sort: {
        fields: publishDate,
        order: DESC
      }
    ) {
      nodes {
        id
        title
        slug
      }
    }
    allStrapiBlog(
      filter: {locale: {eq: $strapiLangKey}}
      sort: {fields: publishDate, order: DESC}
    ) {
      nodes {
        id
        title
        slug
        body {
          data {
            body
          }
        }
        publishDate
      }
    }
  }
`

export default BlogPage
